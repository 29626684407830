import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'

import { FormContainer } from 'Components/Form/formStyled'

const carTypeOption = [
    { value: 'SUV', label: 'SUV' },
    { value: 'Luxury Sedan', label: 'Luxury Sedan' },
    { value: 'Economy', label: 'Economy' },
];
const schema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    firstName: yup.string().required("First name is required"),
    mobileNumber: yup.string().required("Mobile number is required"),
    count: yup.string().required("Count is required"),
    pickupLocation: yup.string().required("Pickup location is required"),
    dropLocation: yup.string().required("Drop location is required"),
    // cabType: yup.string().required("Cab type is required"),
    date: yup.string().required("Date is required"),
    time: yup.string().required("Time is required")
});


function formatTime(inputTime) {
    // Remove non-digit characters and make it a 4-digit value
    const cleanedTime = inputTime.replace(/\D/g, '').padStart(4, '0');

    // Extract hours and minutes
    const hours = cleanedTime.slice(0, 2);
    const minutes = cleanedTime.slice(2, 4);

    // Determine AM/PM
    const amPm = hours >= 12 ? 'PM' : 'AM';

    // Format the time in AM/PM format
    const formattedTime = `${(hours % 12).toString().padStart(2, '0')}:${minutes} ${amPm}`;

    return formattedTime;
}

const RequirementForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const originalDateStr = "2023-10-11";
    const formattedDate = formatDateString(originalDateStr);

    const onSubmit = (data) => {
        console.log(data);
        const payload = {
            ...data,
            time: formatTime(data?.time),
            date: formatDateString(data?.date),
            cabType: data?.cabType?.value,
        }
        console.log('payload === ', payload)
        axios.post('https://qqk4zhkbig.execute-api.ap-southeast-2.amazonaws.com/dev', payload).then((response) => {
            console.log('response === ', response)
        })

    };
    console.log('errors === ', errors);

    return (
        <FormContainer>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-12'>

                        <input type="text" placeholder="First name" {...register("firstName")} />
                        {errors?.firstName && <p>{errors?.firstName?.message}</p>}
                    </div>
                    <div className='col-12'>
                        <input type="text" placeholder="Email" {...register("email")} />

                    </div>
                    {/* <p>{errors.email?.message}</p> */}
                    <div className='col-md-6'>
                        <input type="tel" placeholder="Mobile number" {...register("mobileNumber")} />

                    </div>
                    <div className='col-md-6'>
                        <input type="number" placeholder="Passenger count" {...register("count")} />
                    </div>
                    <div className='col-12'>
                        <input type="text" placeholder="Pickup Location"  {...register("pickupLocation")} />

                    </div>
                    <div className='col-12'>
                        <input type="text" placeholder="Drop Location" {...register("dropLocation")} />

                    </div>
                    {/* <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={colourOptions[0]}
                    options={colourOptions}
                    {...register("cabType", {})}
                /> */}
                    <div className='col-12 mb-24' >
                        <Controller
                            name="cabType"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={carTypeOption}
                                    defaultValue={carTypeOption[0]}
                                // {...register("cabType", {})}
                                />
                            )}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Controller
                            name="date"
                            control={control}
                            render={({ field }) => (
                                <input type="date" {...field}
                                />
                            )}
                        />
                    </div>
                    <div className='col-md-6'>
                        <input type="time" placeholder="Time" {...register("time")} />
                    </div>
                    <div className='col-12'>
                        <input type="submit" value="Book Now" />
                    </div>
                </div>
            </form>
        </FormContainer>

    );

}
function formatDateString(inputDate) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-GB', options);
}
export default RequirementForm