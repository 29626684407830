import * as React from "react";
const SVGComponent = (props) => (
    <svg
        fill="#ffffff"
        width="40px"
        height="40px"
        viewBox="0 0 24 24"
        id="menu"
        data-name="Line Color"
        xmlns="http://www.w3.org/2000/svg"
        className="icon line-color"
        {...props}
    >
        <line
            id="secondary"
            x1={3}
            y1={12}
            x2={21}
            y2={12}
            style={{
                fill: "none",
                stroke: "#f8ca11",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 2,
            }}
        />
        <path
            id="primary"
            d="M9,18H21M3,6H15"
            style={{
                fill: "none",
                stroke: "#ffffff",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 2,
            }}
        />
    </svg>
);
export default SVGComponent;