
// const d = new Date();
// let year = d.getFullYear();
// document.getElementById("demo").innerHTML = year;

const Footer = () => {



    return (
        <>
            <div className="section_footer ">
                <div className="container">
                    <div className="footer_section_2">
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-3">
                                <h2 className="account_text">Address</h2>
                                <p className="ipsum_text">
                                    It is a long established fact that a reader will be distracted by
                                    the readable content of a page when looking at its layout. The
                                    point of using Lorem Ipsum is that it has a more-or-less normal
                                    distribution of letters,{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright_section">
                <div className="container">
                    <p className="copyright">
                        <span id="demo">{new Date().getFullYear()} </span> All Rights Reserved.

                    </p>
                </div>
            </div>
        </>
    );
};
export default Footer;