
import React, { useState } from 'react'
import MenuIcon from "assets/svg/MenuIcon"
import Logo from 'assets/images/logo.png';
// import toggleMenu from 'assets/images/toggle-menu.png';
const Header = () => {
    const [toggle, setToggle] = useState(false)

    return (

        <div id="index.html" className="header_section">
            <div className="container">
                <div className="row">
                    <div className="col-xs-6 col-sm-6 col-lg-3">
                        <div className="logo">
                            <a href="index.html">
                                <img src={Logo} alt='logo' />
                            </a>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-lg-9">
                        <div className="for_mobile">
                            <button
                                onClick={() => setToggle(!toggle)}
                                class="btn menu-icon-btn">
                                <MenuIcon />
                            </button>
                            {toggle &&
                                <div className="menu_text_mobile">
                                    <ul>
                                        <li>
                                            <a href="index.html">Home</a>
                                        </li>
                                        <li>
                                            <a href="#about">About</a>
                                        </li>
                                        <li>
                                            <a href="#taxis">Taxi</a>
                                        </li>
                                        <li>
                                            <a href="#booking">Booking</a>
                                        </li>
                                        <li>
                                            <a href="#contact">Contact Us</a>
                                        </li>

                                        <div id="myNav" className="overlay">
                                            <a
                                                href="#"
                                                className="closebtn"

                                            >
                                                ×
                                            </a>
                                            <div className="overlay-content">
                                                <a href="index.html">Home</a>
                                                <a href="#about">About</a>
                                                <a href="#taxis">Taxi</a>
                                                <a href="#booking">Booking</a>
                                                <a href="#contact">Contact Us</a>
                                            </div>

                                        </div>
                                        {/* <span style="font-size:33px;cursor:pointer; color: #ffffff;" onclick="openNav()">T</span> */}
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className="menu_text">
                            <ul>
                                <li>
                                    <a href="index.html">Home</a>
                                </li>
                                <li>
                                    <a href="#about">About</a>
                                </li>
                                <li>
                                    <a href="#taxis">Taxi</a>
                                </li>
                                <li>
                                    <a href="#booking">Booking</a>
                                </li>
                                <li>
                                    <a href="#contact">Contact Us</a>
                                </li>

                                <div id="myNav" className="overlay">
                                    <a
                                        href="#"
                                        className="closebtn"

                                    >
                                        ×
                                    </a>
                                    <div className="overlay-content">
                                        <a href="index.html">Home</a>
                                        <a href="#about">About</a>
                                        <a href="#taxis">Taxi</a>
                                        <a href="#booking">Booking</a>
                                        <a href="#contact">Contact Us</a>
                                    </div>

                                </div>
                                {/* <span style="font-size:33px;cursor:pointer; color: #ffffff;" onclick="openNav()">T</span> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default Header;