import * as React from "react";
import { useForm } from "react-hook-form";
import Header from "Components/header";
import Footer from "../../Components/footer";
import car from "assets/images/car.png";
import image1 from "assets/images/img-1.png";
import image2 from "assets/images/img-2.png";
import image3 from "assets/images/img-3.png";
import image4 from "assets/images/img-4.png";
import image5 from "assets/images/img-5.png";
import image7 from "assets/images/img-7.png";
import "assets/css/bootstrap.min.css";
import RequirementForm from "Components/Form";
// import "./home.css";

const Home = () => {
  return (
    <>
      <Header />
      <div className="banner_section">
        <div className="container-fluid">
          <div className="carousel-item active">
            <div className="row">
              <div className="col-md-6">
                <div className="flex-wrapper">
                  <div className="book_now">
                    <h1 className="book_text">BOOK NOW</h1>
                    <h1 className="call_text">(+71) 1234567890</h1>
                  </div>
                  <div className="image_1">
                    <img src={image1} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact_bg">
                  <h1 className="booking_text">
                    Book a City Taxi to your destination in town
                  </h1>
                  <div className="input_main">
                    <div className="container">
                      <h2 className="request_text">
                        Your everyday travel partner
                      </h2>
                      <RequirementForm />
                    </div>
                  </div>
                  {/* <div className="send_bt">
                        <button type="submit">Submit</button>
                      </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="taxis" className="taxis_section layout_padding">
        <div className="container">
          <h1 className="our_text">
            Our <span style={{ color: "#f4db31" }}>Taxi</span>
          </h1>
          <div className="taxis_section_2">
            <div className="row">
              <div className="col-sm-4">
                <div className="taxi_main">
                  <div className="round_1">01</div>
                  <h2 className="carol_text">CAR 1</h2>
                  <p className="reader_text">
                    act that a reader will be
                    <br />
                    distracted{" "}
                  </p>
                  <div className="images_2">
                    <a href="#">
                      <img src={image2} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="taxi_main">
                  <div className="round_1">02</div>
                  <h2 className="carol_text">CAR 2</h2>
                  <p className="reader_text">
                    act that a reader will be
                    <br />
                    distracted{" "}
                  </p>
                  <div className="images_2">
                    <img src={image2} />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="taxi_main">
                  <div className="round_1">03</div>
                  <h2 className="carol_text">CAR 3</h2>
                  <p className="reader_text">
                    act that a reader will be
                    <br />
                    distracted{" "}
                  </p>
                  <div className="images_2">
                    <img src={image2} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="booking" className="ride_section layout_padding">
        <div className="container">
          <div className="ride_main">
            <h1 className="ride_text">
              Why Ride <span style={{ color: "#f4db31" }}>With Uloax</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="ride_section_2 layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="image_3">
                <img src={image3} />
              </div>
            </div>
            <div className="col-sm-8">
              <h1 className="cabe_text">Uloax for Every Pocket</h1>
              <p className="long_text">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as It is a long established fact
                that a reader will be distracted by the readable c
              </p>
              <div className="book_bt">
                <a href="#">BOOK NOW</a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="secure_text">Secure and Safer Rides</h1>
              <p className="long_text_2">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as It is a long established fact
                that a reader will be distracted by the readable c
              </p>
              <div className="book_bt_2">
                <a href="#">BOOK NOW</a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="image_3">
                <img src={image4} />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="image_3">
                <img src={image5} />
              </div>
            </div>
            <div className="col-sm-8">
              <h1 className="cabe_text">Uloax for Every Pocket</h1>
              <p className="long_text">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as It is a long established fact
                that a reader will be distracted by the readable c
              </p>
              <div className="book_bt">
                <a href="#">BOOK NOW</a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="secure_text">Secure and Safer Rides</h1>
              <p className="long_text_2">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as It is a long established fact
                that a reader will be distracted by the readable c
              </p>
              <div className="book_bt_2">
                <a href="#">BOOK NOW</a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="image_3">
                <img src={image5} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
